/** @jsx jsx */
import { jsx } from 'theme-ui'
// import { Link } from "gatsby"
// import { RiHeart2Line } from "react-icons/ri";

const Footer = () => (
  <footer 
    className="site-footer"
    // sx={{
    //   bg: 'primary'
    // }}
  >
    <div className="container">
      <p>Copyright © 2021 WCB HOMES LLC, All Rights Reserved</p>
    </div>
  </footer>
)

export default Footer