import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import WcbLogo from './logo-wcb-homes.png'

const Logo = ({title}) => (
  <div className="site-logo">
    <Link to="/">
        {
          WcbLogo ? <img src={WcbLogo} height="100" alt={title} /> : {title}
        }  
    </Link>
  </div>
)

export default Logo

//width=200
//height=200
/* <StaticImage
            src={WcbLogo}
            alt={title}
            placeholder="blurred"
            layout="fixed"
          /> */