/** @jsx jsx */
import { jsx } from 'theme-ui'

const Header = ({children}) => (
  <div className="header-container">
    <div className="outter-header">
      <header 
        className="site-header"
        sx={{
          bg: 'primary'
        }}
      >
        {children}
      </header>
    </div>
  </div>
)

export default Header